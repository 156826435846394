import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import QualifiersList from '../../../components/QualifiersList'

const Wrapper = styled.div`
  max-width: ${(props) => props.theme.containerWidths.medium};
  margin-left: auto;
  margin-right: auto;

  article {
    .two-columns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;

      p {
        margin: 0;

        &:nth-child(odd) {
          font-weight: 500;
        }

        &:nth-child(even) {
          justify-self: end;
        }
      }
    }

    + article {
      margin-top: 40px;

      @media screen and (min-width: ${(props) =>
    props.theme.responsive.large}) {
        margin-top: 60px;
      }
    }
  }
`

const IncentiveTrip = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulIncentiveTripQualifiers {
        edges {
          node {
            qualifiers {
              id
              title
              description
              fixed(width: 160, quality: 60) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const qualifiersList =
    data.allContentfulIncentiveTripQualifiers.edges[0].node.qualifiers || []

  return (
    <Wrapper className="wrap">
      <section className="section">
        <h2 className="headline center-align">How to Qualify</h2>
        <p className="center-align">
          The Seint Reward Trips are designed to push Artists to achieve their dreams. We firmly believe that
          if you put your focus on your passion for the product, the rest will follow. With the recent merge
          of the Leadership Summit and Reward Trips being combined into one trip, more points have been
          allotted for the paid rank of Artist VII and above to account for those leadership roles within the
          company.
        </p>
        <p className="center-align">
          Artists have from{' '}
          <span className="medium-weight">May 1, 2023 - October 31, 2023</span>{' '}
          to earn at least <span className="medium-weight">*25,000 points</span>
          .
        </p>
        <p className="note-text center-align">
          *Must earn a minimum of 3,500 points in 5 out of the 6 months in order
          to qualify. Points can be viewed in your Back Office Dashboard
        </p>
      </section>
      <section className="section">
        <article>
          <p className="title center-align">Personal Sales</p>
          <div className="two-columns">
            <p>1 CV</p>
            <p>1 Point</p>
          </div>
        </article>
        <article>
          <p className="title center-align">Rank Advancements</p>
          <div className="two-columns">
            <p>Artist II</p>
            <p>200 Points</p>
            <p>Artist III</p>
            <p>250 Points</p>
            <p>Artist IV</p>
            <p>300 Points</p>
            <p>Artist V</p>
            <p>350 Points</p>
            <p>Artist VI</p>
            <p>400 Points</p>
            <p>Artist VII</p>
            <p>450 Points</p>
            <p>Artist VIII</p>
            <p>500 Points</p>
            <p>Artist IX</p>
            <p>550 Points</p>
            <p>Artist X</p>
            <p>600 Points</p>
          </div>
        </article>
        <article>
          <p className="title center-align">Paid Rank</p>
          <div className="two-columns">
            <p>Artist I</p>
            <p>150 Points a month</p>
            <p>Artist II</p>
            <p>200 Points a month</p>
            <p>Artist III</p>
            <p>250 Points a month</p>
            <p>Artist IV</p>
            <p>300 Points a month</p>
            <p>Artist V</p>
            <p>350 Points a month</p>
            <p>Artist VI</p>
            <p>400 Points a month</p>
            <p>Artist VII</p>
            <p>1400 Points a month</p>
            <p>Artist VIII</p>
            <p>1600 Points a month</p>
            <p>Artist IX</p>
            <p>1800 Points a month</p>
            <p>Artist X</p>
            <p>2000 Points a month</p>
          </div>
        </article>
      </section>
      <section className="section">
        <article>
          <QualifiersList
            eventName="Incentive Trip"
            qualifiersList={qualifiersList}
          />
        </article>
      </section>
    </Wrapper>
  )
}

export default IncentiveTrip
