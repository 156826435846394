import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'

const ArtistAvatarsList = styled.ul`
  display: grid;
  grid-gap: 30px 20px;
  justify-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;

  li {
    /* max-width: calc(50% - 20px); */
    width: 100%;
    min-width: 120px;
    text-align: center;

    .gatsby-image-wrapper {
      width: 120px !important;
      height: 120px !important;
      margin-bottom: 8px;

      &,
      img {
        border-radius: 50%;
      }
    }
  }

  @media screen and (min-width: calc((20px * 2) + (120px * 2) + 20px)) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: calc((20px * 2) + (152px * 2) + 20px + 1px)) and (max-width: calc((20px * 2) + (152px * 3) + (20px * 2))) {
    max-width: calc((152px * 2) + 20px);
  }

  @media screen and (min-width: calc((20px * 2) + (152px * 2) + 20px + 1px)) {
    grid-template-columns: repeat(auto-fit, minmax(152px, 1fr));

    li {
      max-width: 152px;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    max-width: ${(props) => props.theme.containerWidths.smallMedium};
    margin-top: 40px;

    li {
      min-width: 202px;
      max-width: 202px;

      .gatsby-image-wrapper {
        width: 160px !important;
        height: 160px !important;
      }
    }
  }
`

const ListOfQualifiers = styled.ul`
  padding-bottom: 20px;
  text-align: center;

  > li {
    + li {
      margin-top: 30px;
    }

    .section-title--serif {
      margin-top: 40px;
    }

    .section-title--secondary {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    > li {
      + li {
        margin-top: 80px;
      }

      .section-title--serif {
        max-width: ${(props) => props.theme.containerWidths.smallMedium};
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
`

const ArtistName = styled.p`
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    font-size: 15px;
  }
`

const QualifiersList = ({ qualifiersList, eventName, ...props }) => (
  <ListOfQualifiers {...props}>
    <li>
      <h3 className="headline center-align">Qualifiers</h3>
      {qualifiersList.length ? (
        <>
          <p>
            Congratulations to all of the Artists who qualified for the
            {` ${eventName}`}!
          </p>
          <ArtistAvatarsList>
            {qualifiersList.map((qualifier) => (
              <li key={`${qualifier.id}`}>
                <Img fixed={qualifier.fixed} loading="eager" />
                <ArtistName>{qualifier.title}</ArtistName>
              </li>
            ))}
          </ArtistAvatarsList>
        </>
      ) : (
        <p className="center-align">
          Qualifiers will be announced on this page and in Honor Roll every month as they earn their spots.
        </p>
      )}
    </li>
  </ListOfQualifiers>
)

export default QualifiersList
